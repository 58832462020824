<template>
  <moe-page title="新增店铺折扣">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <moe-steps :active="active" :stepsList="[{ title: '基本信息' }, { title: '优惠门槛和内容' }, { title: '商品选择' }, { title: '完成设置' }]"></moe-steps>
      </template>

      <template v-if="active === 0">
        <moe-form
          ref="shopDiscountForm"
          :showBack="false"
          :model="shopDiscountParams"
          :rules="rules"
          :defaultVerify="false">
          <el-form-item label="活动名称" prop="name">
            <el-input v-model.trim="shopDiscountParams.name" placeholder="请输入活动名称" clearable maxlength="20"></el-input>
            <div class="color-info font-10">活动名称仅用于商家自己管理活动，不会显示给用户</div>
          </el-form-item>

          <el-form-item label="优惠类型" prop="level">
            <moe-radio-group :disabled="IS_EDIT" v-model="shopDiscountParams.level" :radioBoxList="$moe_data.discountLevel"></moe-radio-group>
            <div class="color-info font-10 df aic">
              <moe-icon name="reminder" size="20"></moe-icon>
              <div class="ml-5">活动创建后，优惠类型将无法修改</div>
            </div>
          </el-form-item>

          <el-form-item label="活动时间" prop="startTime">
            <el-date-picker
              style="width: 100%;"
              placement="bottom-start"
              v-model="datetime"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="活动时间开始时间"
              end-placeholder="活动时间结束时间"
              @change="(datetime) => ([shopDiscountParams.startTime, shopDiscountParams.endTime] = datetime || ['', ''])" />
          </el-form-item>

          <template slot="tool">
            <el-button v-if="IS_EDIT" type="primary" icon="el-icon-right" :loading="shopDiscountLoad" @click="handleStep(1, 'updateDiscount')">下一步</el-button>
            <el-button v-else type="primary" icon="el-icon-thumb" :loading="shopDiscountLoad" @click="addDiscount()">{{ shopDiscountLoad ? '保存中' : '保存并继续' }}</el-button>
          </template>
        </moe-form>
      </template>

      <template v-if="active > 0">
        <moe-describe-list title="" :col="2">
          <moe-describe-item label="活动名称"> {{ shopDiscountParams.name }} </moe-describe-item>
          <moe-describe-item label="优惠条件"> {{ $moe_format.getShopDiscountMethod(shopDiscountParams.method) }} </moe-describe-item>
          <moe-describe-item label="优惠类型"> {{ $moe_format.getDiscountLevel(shopDiscountParams.level) }} </moe-describe-item>
          <moe-describe-item label="活动时间">{{ shopDiscountParams.startTime }} 至 {{ shopDiscountParams.endTime }} </moe-describe-item>
          <moe-describe-item label="创建时间"> {{ createTime }} </moe-describe-item>
        </moe-describe-list>
      </template>

      <template v-if="active === 1">
        <div class="df1 df fdc ml-30">
          <moe-form
            ref="shopDiscountMethodForm"
            class="auto-form"
            :showBack="false"
            :model="shopDiscountParams"
            :rules="rules"
            :defaultVerify="false">
            <div class="fwb mb-10">优惠条件</div>
            <el-form-item label="优惠条件" label-width="100px" prop="method" :rules="rules.method">
              <moe-radio-group v-model="shopDiscountParams.method" :radioBoxList="$moe_data.shopDiscountMethod" @change="handleChange"></moe-radio-group>
            </el-form-item>

            <div v-for="(item, index) in shopDiscountParams.contents" :key="index">
              <div class="fwb mb-20">{{ `优惠门槛及内容 - 阶梯${index + 1}` }}</div>
              <el-form-item label-width="100px" :prop="`contents.${index}.condition`" :rules="rules.condition">
                <div class="df aic" v-if="shopDiscountParams.method === 'DISCOUNT'">
                  <div>满</div>
                  <el-input class="ml-5 mr-5 w-100" maxlength="8" placeholder="整数" :value="item.condition" @input="(value) => changeContentsCondition(value, index)"></el-input>
                  <div>件</div>
                </div>
                <div class="df aic" v-else-if="shopDiscountParams.method === 'REDUCE'">
                  <div>满</div>
                  <el-input class="ml-5 mr-5 w-100" maxlength="8" placeholder="金额" :value="item.condition" @input="(value) => changeContentsCondition(value, index)"></el-input>
                  <div>元</div>
                </div>
              </el-form-item>

              <el-form-item label-width="100px" :prop="`contents.${index}.discount`" :rules="rules.discount">
                <div class="df aic" v-if="shopDiscountParams.method === 'DISCOUNT'">
                  <div>打</div>
                  <el-input class="ml-5 mr-5 w-100" maxlength="8" placeholder="1位小数" :value="item.discount" @input="(value) => changeContentsDiscount(value, index)"></el-input>
                  <div class="mr-5">折</div>
                  <el-tooltip content="请输入3.0~9.9之间的值，最多保留一位小数" placement="top">
                    <moe-icon name="reminder" size="16px"></moe-icon>
                  </el-tooltip>
                </div>
                <div class="df aic" v-else-if="shopDiscountParams.method === 'REDUCE'">
                  <div>减</div>
                  <el-input class="ml-5 mr-5 w-100" maxlength="8" placeholder="金额" :value="item.discount" @input="(value) => changeContentsDiscount(value, index)"></el-input>
                  <div>元</div>
                </div>
              </el-form-item>
            </div>

            <div class="mt-20">
              <el-button type="primary" icon="el-icon-circle-plus-outline" @click="handleAddContents()">增加优惠阶梯</el-button>
              <el-button :disabled="deleteDisabled" type="danger" icon="el-icon-delete" @click="handleDelContents()">删除优惠阶梯</el-button>
              <el-button type="text" icon="el-icon-warning-outline">最多可设置五级优惠，删除只能删除最近一级优惠。</el-button>
            </div>

            <template slot="tool">
              <el-button type="primary" plain icon="el-icon-back" :loading="shopDiscountLoad" @click="handleStep(0, 'back')">{{ shopDiscountLoad ? '保存中' : '上一步' }}</el-button>
              <el-button type="primary" icon="el-icon-right" :loading="shopDiscountLoad" @click="handleStep(2, 'updateDiscountContents')">{{ shopDiscountLoad ? '保存中' : '下一步' }}</el-button>
            </template>
          </moe-form>
        </div>
      </template>

      <template v-if="active === 2">
        <div class="df1 df fdc">
          <moe-form ref="goodsDiscountGoodsForm" :model="shopDiscountParams" class="auto-form" :showBack="false" label-width="100px" :rules="rules">
            <template v-if="shopDiscountParams.level === 'ALL'">
              <div class="fwb mb-20 ml-30">指定活动商品</div>
              <el-form-item>
                <div>指定商品：全部商品(已参加其他活动的商品除外)</div>
                <div>1.本次活动的商品范国是，本活动时段内，全店商品中未参加其它活动的所有商品</div>
                <div>2.活动生效时段内新发布的商品，也会参加到本活动中</div>
              </el-form-item>
            </template>

            <template v-else-if="shopDiscountParams.level === 'PART'">
              <div style="height: 700px;min-height: 700px;" class="df fdc">
                <moe-table
                  :stripe="true"
                  :key="itemKey"
                  :numberShow="false"
                  :data="shopDiscountParams.configList"
                  :mode="false"
                  :showPage="false"
                  :params="{ pageNum: 1 }"
                  emptyText="请添加活动商品"
                  rowKey="goodsId">
                  <!-- 操作按钮 -->
                  <el-form-item prop="configList" slot="tool">
                    <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showDialog = true;">添加商品</el-button>
                  </el-form-item>

                  <el-table-column label="商品信息" min-width="200">
                    <template slot-scope="{ row }">
                      <div class="df aic">
                        <moe-image :src="row.coverUrl" width="80px" height="80px" />
                        <div style="text-align:left" class="ml-10">
                          <p class="fwb">{{ row.name }}</p>
                          <p class="font-12 color-info">商品编号: {{ row.goodsId }}</p>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="销售价" min-width="80">
                    <template slot-scope="{ row }">
                      {{ `￥ ${row.minPrice}` }}{{ $moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : '' }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="stock" label="库存" min-width="80" />
                  <el-table-column label="操作" width="200" fixed="right">
                    <template slot-scope="{ row, $index }">
                      <el-button icon="el-icon-delete" size="small" type="danger" @click="handleDelete(row, $index)">撤出活动</el-button>
                    </template>
                  </el-table-column>
                </moe-table>
              </div>
            </template>

            <template slot="tool">
              <el-button type="primary" plain icon="el-icon-back" :loading="shopDiscountLoad" @click="handleStep(1, 'back')">{{ shopDiscountLoad ? '保存中' : '上一步' }}</el-button>
              <el-button type="primary" icon="el-icon-upload2" :loading="shopDiscountLoad" @click="handleStep(4, 'updateDiscountConfigList')">{{ shopDiscountLoad ? '保存中' : '提交' }}</el-button>
            </template>
          </moe-form>
        </div>
      </template>

      <template v-if="active === 4">
        <div class="df aifs ml-40 mb-40">
          <el-button
            style="font-size: 26px;"
            icon="el-icon-circle-check"
            type="text">
            "店铺折扣活动"设置成功！
          </el-button>
        </div>
        <div class="df aifs ml-40">
          <el-button type="primary" icon="el-icon-back" :loading="shopDiscountLoad" @click="$moe_coordinator.navigateBack()">返回列表</el-button>
        </div>
      </template>
    </moe-card>

    <goodsDialog
      type="SHOP"
      :showActivityRepel="true"
      :showDialog.sync="showDialog"
      :defaultSelectIds="shopDiscountParams.configList && shopDiscountParams.configList.length ? shopDiscountParams.configList.map(({ goodsId }) => goodsId) : []"
      @close="handleClose">
    </goodsDialog>
  </moe-page>
</template>

<script>
import goodsDialog from '@/views/ActivityManage/components/goodsDialog.vue';
export default {
  name: 'ActivityManageShopDiscountAdd',
  components: {
    goodsDialog
  },
  data() {
    const checkCondition = (rule, value, callback) => {
      if (!value) {
        if (this.shopDiscountParams.method === 'DISCOUNT') {
          return callback(new Error(`请输入件数`));
        } else if (this.shopDiscountParams.method === 'REDUCE') {
          return callback(new Error(`请输入金额`));
        } else {
          callback();
        }
      } else {
        let index = Number(rule.fullField.split('.')[1]);
        let findItem = this.shopDiscountParams.contents[index];

        if (this.shopDiscountParams.method === 'DISCOUNT') {
          if (!/^[1-9]\d*$/.test(findItem.condition)) {
            return callback(new Error(`请输入大于0的正整数`));
          } else if (this.shopDiscountParams.contents.length > 1 && index >= 1) {
            if (this.$moe_math.mathLessThanOrEqualTo(findItem.condition, this.shopDiscountParams.contents[index - 1].condition)) {
              return callback(new Error(`优惠门槛应比上一层级高`));
            } else {
              callback();
            }
          } else {
            callback();
          }
        } else if (this.shopDiscountParams.method === 'REDUCE') {
          if (this.$moe_math.mathLessThanOrEqualTo(findItem.condition, 0)) {
            return callback(new Error(`请输入大于0的金额`));
          } else if (this.shopDiscountParams.contents.length > 1 && index >= 1) {
            if (this.$moe_math.mathLessThanOrEqualTo(findItem.condition, this.shopDiscountParams.contents[index - 1].condition)) {
              return callback(new Error(`优惠门槛应比上一层级高`));
            } else {
              callback();
            }
          } else {
            callback();
          }
        } else {
          callback();
        }
      }
    }
    const checkDiscount = (rule, value, callback) => {
      if (!value) {
        if (this.shopDiscountParams.method === 'DISCOUNT') {
          return callback(new Error(`请输入折扣`));
        } else if(this.shopDiscountParams.method === 'REDUCE') {
          return callback(new Error(`请输入金额`));
        } else {
          callback();
        }
      } else {
        let index = Number(rule.fullField.split('.')[1]);
        let findItem = this.shopDiscountParams.contents[index];

        if (this.shopDiscountParams.method === 'DISCOUNT') {
          if (!/^\d+(\.\d{0,1})?$/.test(findItem.discount)) {
            return callback(new Error(`请输入只有一位小数点`));
          } else if (this.$moe_math.mathLessThan(findItem.discount, 3) || this.$moe_math.mathGreaterThan(findItem.discount, 9.9)) {
            return callback(new Error(`请输入3.0~9.9之间的值，最多保留一位小数`));
          } else if (this.shopDiscountParams.contents.length > 1 && index >= 1) {
            if (this.$moe_math.mathGreaterThanOrEqualTo(findItem.discount, this.shopDiscountParams.contents[index - 1].discount)) {
              return callback(new Error(`优惠力度应比上一层级大`));
            } else {
              callback();
            }
          } else {
            callback();
          }
        } else if (this.shopDiscountParams.method === 'REDUCE') {
          let discount70 = this.$moe_math.mathDiv(Math.ceil(this.$moe_math.mathMul(this.$moe_math.mathMul(findItem.condition, 0.7), 100)), 100);
          if (this.$moe_math.mathLessThanOrEqualTo(findItem.discount, 0)) {
            return callback(new Error(`请输入大于0的金额`));
          } else if (this.$moe_math.mathGreaterThan(findItem.discount, discount70)) {
            return callback(new Error(`减免金额不能超过满足金额的70%`));
          } else if (this.shopDiscountParams.contents.length > 1 && index >= 1) {
            if (this.$moe_math.mathLessThanOrEqualTo(this.$moe_math.mathDiv(findItem.discount, findItem.condition), this.$moe_math.mathDiv(this.shopDiscountParams.contents[index - 1].discount, this.shopDiscountParams.contents[index - 1].condition))) {
              return callback(new Error(`优惠力度应比上一层级大`));
            } else {
              callback();
            }
          } else {
            callback();
          }
        } else {
          callback();
        }
      }
    }
    const verify = this.$moe_verify.verifyForm
    const rules = {
      name: verify.isEmpty('请输入活动名称'),
      level: verify.isEmpty('请选择优惠类型'),
      startTime: verify.isEmpty('请选择活动时间'),
      method: verify.isEmpty('请选择优惠条件'),
      condition: [{ required: true, validator: checkCondition, trigger: ['blur', 'change'] }],
      discount: [{ required: true, validator: checkDiscount, trigger: ['blur', 'change'] }],
      configList: verify.isEmpty('请添加活动商品'),
    }
    return {
      active: 0,
      shopDiscountParams: {
        id: "",
        level: "PART",
        method: "DISCOUNT",
        name: "",
        startTime: "",
        endTime: "",
        contents: [],
        configList: [],
      },
      contentsItem: {
        condition: '',
        discount: '',
      },
      datetime: [],
      createTime: '',
      rules,
      shopDiscountLoad: false,
      itemKey: Math.random(),
      showDialog: false,
    }
  },
  computed: {
    IS_EDIT() {
      return this.shopDiscountParams.id ? true : false;
    },
    deleteDisabled() {
      if (this.shopDiscountParams.contents.length <= 1) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    /** 添加商品 确认 */
    handleClose(selectList) {
      selectList.forEach((item) => {
        let findItem = this.shopDiscountParams.configList.find(({ goodsId }) => item.id === goodsId)
        if (!findItem) {
          this.shopDiscountParams.configList.push({
            ...item,
            goodsId: item.id,
            id: '',
          })
        }
      })

      this.showDialog = false;
    },
    /** 删除添加的商品 */
    handleDelete({ name }, index) {
      this.$moe_layer.confirm(`您确定要撤出当前选项"${name}”吗？`, () => {
        this.shopDiscountParams.configList.splice(index, 1);
      });
    },
    handleAddContents() {
      this.$refs['shopDiscountMethodForm'].validate(() => {
        if (this.shopDiscountParams.contents.length >= 5) {
          return this.$moe_msg.warning('最多可设置五级优惠');
        }
        this.shopDiscountParams.contents.push(this.$moe_lodash.cloneDeepData(this.contentsItem));
      });
    },
    handleDelContents() {
      this.$moe_layer.confirm(`您确定要删除"优惠门槛及内容 - 阶梯${this.shopDiscountParams.contents.length}"吗？`, () => {
        this.shopDiscountParams.contents.splice(this.shopDiscountParams.contents.length - 1, 1);
      })
    },
    /** 优惠门槛及内容 满件 | 满元 */
    changeContentsCondition(value, index) {
      let findItem = this.shopDiscountParams.contents[index];
      findItem.condition = value
      if (!value) return false

      if (this.shopDiscountParams.method === 'DISCOUNT') {
        try {
          findItem.condition = value.replace(/[^0-9.]/g, "").replace(/^0+(\d)|(\.)/, '$1');
          this.$refs['shopDiscountMethodForm'].validate();
        } catch {
          console.log('error')
        }
      } else if (this.shopDiscountParams.method === 'REDUCE') {
        try {
          findItem.condition = value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/, '$1$2$3').replace(/^0+(\d)/, '$1').replace(/^\./, '0.').match(/^\d*(\.?\d{0,2})/g)[0] || '';
          this.$refs['shopDiscountMethodForm'].validate();
        } catch {
          console.log('error')
        }
      }
    },
    /** 优惠门槛及内容 打折 | 减元 */
    changeContentsDiscount(value, index) {
      let findItem = this.shopDiscountParams.contents[index];
      findItem.discount = value
      if (!value) return false
      try {
        findItem.discount = value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/, '$1$2$3').replace(/^0+(\d)/, '$1').replace(/^\./, '0.').match(/^\d*(\.?\d{0,2})/g)[0] || '';
        this.$refs['shopDiscountMethodForm'].validate();
      } catch {
        console.log('error')
      }
    },
    handleChange(value) {
      this.shopDiscountParams.method = value;
      try {
        this.$refs['shopDiscountMethodForm'].validate();
      } catch {
        console.log('error')
      }
    },
    handleStep(active, type) {
      switch (type) {
        /** 返回上一步 */
        case 'back':
          this.$msgbox({
            title: '确定返回上一步?',
            message: `即将返回上一步，如有优惠设置没保存，请取消并点击「下一步」按钮保存`,
            showCancelButton: true,
            confirmButtonText: '取消',
            cancelButtonText: '上一步',
          }).then(() => {
            // 取消不返回
          }).catch(() => {
            this.active = active;
          })
          break;
        case 'updateDiscount':
          this.updateDiscount(active);
          break;
        case 'updateDiscountContents':
          this.updateDiscountContents(active);
          break;
        case 'updateDiscountConfigList':
          this.updateDiscountConfigList(active);
          break;
        default:
          this.active = active;
          break;
      }
    },
    /** 修改自选商品 */
    updateDiscountConfigList(active) {
      this.$refs['goodsDiscountGoodsForm'].validate(() => {
        if (this.shopDiscountParams.level === 'PART') {
          this.shopDiscountLoad = true;
          this.$moe_api.SHOP_DISCOUNT.updateDiscountConfigList({
            configList: this.shopDiscountParams.configList.map(({ goodsId, id }) => {
              return {
                goodsId,
                id: id || '',
              }
            }),
            id: this.shopDiscountParams.id,
            publish: true
          }).then(() => {
            this.active = active;
          }).finally(() => {
            this.shopDiscountLoad = false;
          });
        } else {
          this.shopDiscountLoad = true;
          this.$moe_api.SHOP_DISCOUNT.updateDiscountConfigList({
            id: this.shopDiscountParams.id,
            publish: true
          }).then(() => {
            this.active = active;
          }).finally(() => {
            this.shopDiscountLoad = false;
          });
        }
      })
    },
    /** 更新优惠条件 | 优惠门槛及内容  */
    updateDiscountContents(active) {
      this.$refs['shopDiscountMethodForm'].validate(() => {
        this.shopDiscountLoad = true;
        let { id, method, contents } = this.shopDiscountParams;
        this.$moe_api.SHOP_DISCOUNT.updateDiscount({
          id, method, contents
        }).then((data) => {
          if (data.code === 200) {
            this.active = active;
          } else {
            this.$moe_msg.error(data.message);
          }
        }).finally(() => {
          this.shopDiscountLoad = false;
        });
      })
    },
    /** 修改店铺活动 */
    updateDiscount(active) {
      this.$refs['shopDiscountForm'].validate(() => {
        if (this.shopDiscountParams.level === 'ALL') {
          this.$moe_layer.alert(`同一个宝贝设置的全店和自选活动时间有重叠时，该时段该宝贝仅生效自选活动哦！`, () => {
            this.req_updateDiscount(active);
          })
        } else {
          this.req_updateDiscount(active);
        }
      })
    },
    /** 修改店铺活动 request */
    req_updateDiscount(active) {
      this.shopDiscountLoad = true;
      let { id, name, startTime, endTime } = this.shopDiscountParams;
      this.$moe_api.SHOP_DISCOUNT.updateDiscount({ id, name, startTime, endTime }).then((data) => {
        if (data.code === 200) {
          this.active = active;
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.shopDiscountLoad = false;
      });
    },
    /** 创建店铺活动 */
    addDiscount() {
      this.$refs['shopDiscountForm'].validate(() => {
        if (this.shopDiscountParams.level === 'ALL') {
          this.$moe_layer.alert(`同一个宝贝设置的全店和自选活动时间有重叠时，该时段该宝贝仅生效自选活动哦！`, () => {
            this.req_addDiscount();
          })
        } else {
          this.req_addDiscount();
        }
      })
    },
    /** 创建店铺活动 request */
    req_addDiscount() {
      this.shopDiscountLoad = true;
      this.$moe_api.SHOP_DISCOUNT.addDiscount(this.shopDiscountParams).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('新增店铺折扣成功');
          this.$router.replace({
            query: {
              id: data.result.id
            }
          })
          this.getDiscountDetail();
          this.handleStep(1);
        } else {
          this.$moe_layer.alert(data.message)
        }
      }).finally(() => {
        this.shopDiscountLoad = false;
      })
    },
    /** 获取折扣活动详情 */
    getDiscountDetail() {
      if (this.$route.query.id) {
        this.$moe_api.SHOP_DISCOUNT.getDiscountDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code === 200) {
            let { id, name, method, level, startTime, endTime, createTime, contents, configList } = data.result;
            this.shopDiscountParams = {
              id,
              name,
              method,
              level,
              startTime,
              endTime,
              contents: [],
              configList: [],
            };

            this.createTime = createTime;
            this.datetime = [startTime, endTime];

            if (contents && contents.length) {
              this.shopDiscountParams.contents = contents;
            } else {
              this.shopDiscountParams.contents.push(this.$moe_lodash.cloneDeepData(this.contentsItem));
            }

            if (configList.length) {
              this.getQueryShopGoodsByIds(configList);
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
    /** 根据商品ID获取商品列表 */
    getQueryShopGoodsByIds(configList) {
      this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: configList.map(({ goodsId }) => goodsId).join(',') }).then((data) => {
        if (data.code === 200) {
          this.shopDiscountParams.configList = configList.map((item) => {
            let findGoodsItem = data.result.find(({ id }) => item.goodsId === id);
            return {
              ...findGoodsItem,
              goodsId: item.goodsId,
              id: item.id,
            }
          })
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
  },
  mounted() {
    this.getDiscountDetail();
  }
}
</script>

<style lang="scss"></style>